<style lang="less" scoped>
	.content {
		background: #f5f5f5;
	}

	.title {
		display: flex;
		width: 1301px;
		margin: 0 auto;
		padding-top: 82px;
		font-weight: bold;

		div {
			font-size: 26px;
			width: 150px;
			cursor: pointer;
		}

		.active {
			// transition: 1s;

			padding-top: 10px;
			width: 100px;
			border-bottom: 2px solid #932224;
			margin: 0 auto;
		}
	}
</style>
<template>
	<div class="content">
		<div class="title">
			<div v-for="(item,index) in title" :key="index" :style="{'color':headTxtIndex==index?'#932224':'#666666'}"
				@click="textClick(index)">
				{{item.value}}
				<div :class="{active:headTxtIndex==index}"></div>
			</div>
		</div>
		<work v-show="headTxtIndex==0"></work>
		<author v-show="headTxtIndex==1" :index="authorIndex" :ruleForm="contentList[authorIndex]"
			@saveAuthor="saveAuthor" @submit="submit" @backtrack="backtrack" @authorArr="btnTap"></author>
	</div>
</template>

<script>
	import work from "./work"
	import author from "../addAuthor/author.vue"
	export default {
		components: {
			work,
			author
		},
		data() {
			return {
				title: [{
						value: this.$t('newText.msg1'),
						show: true,
					},
					{
						value: this.$t('newText.msg2'),
						show: false,
					},
				],
				headTxtIndex: 0,
				obj: {},
				contentList: [],
				authorIndex: 0,
				productionObj: {}
			}
		},
		mounted() {
			this.workInformationList()

		},
		methods: {
			async addAuthor() {
				let _this = this
				try {
					var params = {
						entries_id: _this.$route.query.id,
					}
					_this.$nextTick(async () => {
						let res = await _this.$axios.post('entries/getTempAuthor', params);
						if (res.code == 1) {
							if (res.data.length != 0) {
								_this.contentList = res.data
							} else {
								this.indormationList()

							}
							// this.$router.push('/personalCenter') 
						}
					})
				} catch (error) {
					console.log(error);
				}
			},
			async saveAuthor(a, b) {
				let _this = this
				if (this.contentList[b]) {
					this.contentList[b] = a
				} else {
					this.contentList.push(a)
				}
				try {

					var params = {
						entries_id: _this.$route.query.id,
						author_list: _this.contentList
					}
					_this.$nextTick(async () => {
						let res = await _this.$axios.post('entries/saveTempAuthor', params);
						console.log(res)
						if (res.code == 1) {
							_this.$message({
								offset: 100,
								message: res.msg,
								type: 'success'
							});
							// this.$router.push('/personalCenter') 
						}
					})
				} catch (error) {
					console.log(error);
				}
			},
			async submit(a, b) {
				console.log(a)
				
				if (this.contentList[b]) {
					this.contentList[b] = a
				} else {
					// this.contentList.push(a)
				}
				console.log(this.contentList)
				let _this = this
				try {
					var params = {
						id: this.$route.query.id,
					}
					_this.contentList.forEach((item, index) => {
						_this.$set(params, 'author_list[' + index + '][image]', item.image)
						_this.$set(params, 'author_list[' + index + '][en_name]', item.en_name)
						_this.$set(params, 'author_list[' + index + '][en_nationality]', item.en_nationality)
						_this.$set(params, 'author_list[' + index + '][birth]', item.birth)
						_this.$set(params, 'author_list[' + index + '][en_sex]', item.en_sex)
						if(item.en_city){
							_this.$set(params, 'author_list[' + index + '][en_city]', item.en_city[0] + '-' + item.en_city[1] + '-' + item.en_city[2])
						}
						
						console.log("2")
						_this.$set(params, 'author_list[' + index + '][mobile]', item.mobile)
						_this.$set(params, 'author_list[' + index + '][id_card]', item.id_card)
						console.log("3")
						_this.$set(params, 'author_list[' + index + '][en_address]', item.en_address)
						_this.$set(params, 'author_list[' + index + '][en_company]', item.en_company)
						_this.$set(params, 'author_list[' + index + '][en_position]', item.en_position)
						
						item.school.forEach((element, i) => {
							_this.$set(params, 'author_list[' + index + '][school][' + i +
								'][en_school_name]', element.en_school_name)
							_this.$set(params, 'author_list[' + index + '][school][' + i +
								'][en_post]', element.en_post)
							_this.$set(params, 'author_list[' + index + '][school][' + i +
								'][start_time]', element.start_time / 1000)
							_this.$set(params, 'author_list[' + index + '][school][' + i +
								'][end_time]', element.end_time / 1000)
						})
						
						item.working.forEach((element, i) => {
							_this.$set(params, 'author_list[' + index + '][working][' + i +
								'][en_company_name]', element.en_company_name)
							_this.$set(params, 'author_list[' + index + '][working][' + i +
								'][company_mobile]', element.company_mobile)
							_this.$set(params, 'author_list[' + index + '][working][' + i +
								'][en_post]', element.en_post)
							_this.$set(params, 'author_list[' + index + '][working][' + i +
								'][start_time]', element.start_time / 1000)
							_this.$set(params, 'author_list[' + index + '][working][' + i +
								'][end_time]', element.end_time / 1000)
						})
						item.award.forEach((element, i) => {
							_this.$set(params, 'author_list[' + index + '][award][' + i +
								'][en_court]', element.en_court)
							_this.$set(params, 'author_list[' + index + '][award][' + i +
								'][en_prize]', element.en_prize)
							_this.$set(params, 'author_list[' + index + '][award][' + i +
								'][reward_time]', element.reward_time / 1000)
						})
					})
					this.$nextTick(async () => {
						console.log(params)
						let res = await _this.$axios.post('entries/addAuthor', params);
						console.log(res)
						if (res.code == 1) {
							_this.$message({
								offset: 100,
								message: res.msg,
								type: 'success'
							});
							this.$router.push('/personalCenter')
						}
					})
				} catch (error) {
					console.log(error);
				}
			},
			backtrack(a, b) {
				console.log(a, b)
				this.authorIndex = this.authorIndex - 1
			},
			btnTap(a, b) {
				console.log(a, b)
				if (this.contentList[b]) {
					console.log("有")
					// this.contentList.push({
					// 	en_name: this.obj.author_lsit[b],
					// 	image: "",
					// 	// en_name: "",
					// 	en_nationality: "",
					// 	birth: "",
					// 	en_sex: "",
					// 	en_city: [],
					// 	mobile: "",
					// 	id_card: "",
					// 	en_address: "",
					// 	en_company: "",
					// 	en_position: "",
					// 	school: [{
					// 		en_school_name: "",
					// 		en_post: "",
					// 		start_time: "",
					// 		end_time: ""
					// 	}],
					// 	working: [{
					// 		en_company_name: "",
					// 		en_post: "",
					// 		company_mobile: "",
					// 		start_time: "",
					// 		end_time: "",
					// 	}],
					// 	award: [{
					// 		en_court: "",
					// 		en_prize: "",
					// 		reward_time: "",
					// 	}]
					// })
				} else {
					console.log("没有")
					this.contentList.push({
						en_name: this.obj.author_lsit[b],
						image: "",
						// en_name: "",
						en_nationality: "",
						birth: "",
						en_sex: "",
						en_city: [],
						mobile: "",
						id_card: "",
						en_address: "",
						en_company: "",
						en_position: "",
						school: [{
							en_school_name: "",
							en_post: "",
							start_time: "",
							end_time: ""
						}],
						working: [{
							en_company_name: "",
							en_post: "",
							company_mobile: "",
							start_time: "",
							end_time: "",
						}],
						award: [{
							en_court: "",
							en_prize: "",
							reward_time: "",
						}]
					})
				}
				this.authorIndex = this.authorIndex + 1
			},
			async indormationList() {
				try {
					let _this = this
					let params = {
						id: this.$route.query.id
					}
					let res = await this.$axios.get('entries/authorList', {
						params: params
					});
					console.log(res)
					if (res.code == 1) {
						this.contentList = res.data
						if (res.data.length == 0) {
							// console.log(_this.productionObj.author_lsit)
							this.contentList = [{
								en_name: _this.obj.author_lsit[0],
								image: "",
								// en_name: "",
								en_nationality: "",
								birth: "",
								en_sex: "",
								en_city: [],
								mobile: "",
								id_card: "",
								en_address: "",
								en_company: "",
								en_position: "",
								school: [{
									en_school_name: "",
									en_post: "",
									start_time: "",
									end_time: ""
								}],
								working: [{
									en_company_name: "",
									en_post: "",
									company_mobile: "",
									start_time: "",
									end_time: "",
								}],
								award: [{
									en_court: "",
									en_prize: "",
									reward_time: "",
								}]
							}]
						}
						res.data.forEach((item, index) => {
							item.en_city = item.en_city.split("-")
							item.school.forEach((element, i) => {
								element.start_time = element.start_time * 1000
								element.end_time = element.end_time * 1000
							})
							item.working.forEach((element, i) => {
								element.start_time = element.start_time * 1000
								element.end_time = element.end_time * 1000
							})
							item.award.forEach((element, i) => {
								element.reward_time = element.reward_time * 1000
							})
						})
						// this.$router.push('personalCenter')
					}
				} catch (error) {
					console.log(error);
				}
			},
			async workInformationList() {
				try {
					let _this = this
					let params = {
						id: this.$route.query.id,
					}
					let res = await this.$axios.get('entries/detail', {
						params: params
					});
					console.log(res)
					if (res.code == 1) {
						this.obj = res.data
						this.addAuthor()
						// this.$router.push('personalCenter')
					}
				} catch (error) {
					console.log(error);
				}
			},
			textClick(txt) {
				this.headTxtIndex = txt
				this.title.forEach(element => {
					element.show = element.value == txt ? true : false
				})
			},
		}
	}
</script>
