<template>
	<div>
		<div class="whether_conten">
			<div class="whether_box flex_disp cursorS" v-for="(item,index) in whether" :key="index"
				@click.stop="whetherClick(item.id)">
				<div class="whether" :class="[radio==item.id?'whether_color':'']"><span></span></div>
				<span>{{item.name||item}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			whether: [Array],
			radio: [Number,String],
		},
		methods: {
			whetherClick(index) {
				this.$emit('whetherClick', index);
			}
		}
	}
</script>

<style lang="less">
	.whether_conten {
		display: flex;
		font-size: 22px;
		padding: 40px 0;

		.whether_box {
			overflow: visible !important;
			>span {
				padding: 0 58px 0 16px;
			}

			.whether {
				width: 23px;
				height: 23px;
				min-height: 23px;
				max-height: 23px;
				min-width: 23px;
				max-width: 23px;
				border: 1px solid #000000;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				>span {
					display: inline-block;
					width: 50%;
					height: 50%;
					background-color: #fff;
					border-radius: 50%;
				}
			}
		}
	}

	.whether_color {
		border-color: #932224 !important;
		span {
			background-color: #932224 !important;
			animation-name: rubberband-horizontal;
			animation-duration: 1s;
			animation-timing-function: ease;
			animation-delay: 0s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: none;
		}
	}

	@keyframes rubberband-horizontal {
		0% {
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
		}

		30% {
			-webkit-transform: scale3d(.75, 1.25, 1);
			transform: scale3d(.75, 1.25, 1);
		}

		40% {
			-webkit-transform: scale3d(1.25, .75, 1);
			transform: scale3d(1.25, .75, 1);
		}

		50% {
			-webkit-transform: scale3d(.85, 1.15, 1);
			transform: scale3d(.85, 1.15, 1);
		}

		65% {
			-webkit-transform: scale3d(1.05, .95, 1);
			transform: scale3d(1.05, .95, 1);
		}

		75% {
			-webkit-transform: scale3d(.95, 1.05, 1);
			transform: scale3d(.95, 1.05, 1);
		}

		100% {
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
		}
	}
</style>
