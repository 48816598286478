<style lang="less" scoped>
	.content {
		padding-bottom: 100px;

		.head {
			width: 1301px;
			height: 609px;
			background: #FFFFFF;
			margin: 50px auto;
			padding: 67px 47px;

			.head_box {
				width: 1207px;
				height: 475px;
				background: #F5F5F5;
				padding: 44px 20px;
				display: flex;
				position: relative;

				.left {
					width: 392px;
					height: 392px;
				}

				.right {
					padding-left: 77px;

					p {
						padding-bottom: 59px;
						font-size: 22px;
						text-align: left;

						span {
							color: #666666;
						}
					}
				}
			}
		}

		.basic {

			width: 1301px;
			margin: 0 auto;
			margin-top: 20px;
		}

		.basicForm {
			/* margin: 0 auto; */
			background-color: #ffffff;
			margin-top: 44px;
			padding: 73px 63px 73px 63px;
			text-align: left;

			.input {
				width: 410px;
				height: 58px;
				border: 1px solid #979797;
				text-align: left;
				font-size: 22px;
				padding: 8px 44px;

			}

			.essential_text {
				// padding: 40px;
				font-size: 22px;
			}

			.btn {
				width: 103px;
				height: 40px;
				border: 1px solid #979797;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 15px;
			}

			.work_img {
				.explain {
					font-size: 22px;
				}

				.img {
					width: 271px;
					height: 194px;
					margin: 22px 22px 0 0;

					img {
						object-fit: cover;
					}

				}

				.img_box {
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
				}
			}

			.work_box {
				display: flex;

				.box_content {
					width: 33.33%;

					.title {
						font-size: 22px;
						padding-top: 80px;
					}

					.video {
						margin-top: 20px;

						video {
							width: 274px;
							height: 194px;
						}
					}
				}
			}
		}

		.tittle {
			font-size: 28px;
			text-align: left;
			margin-top: 44px
		}
	}

	/deep/ .el-form-item__label {
		// max-width: 150px;
		width:165px !important;
		font-size: 22px;
		// height: 58px;
		line-height: 58px;
	}

	/deep/ .el-radio__label {
		font-size: 22px;
	}

	.qingkuang {
		width: 275px;
		height: 196px;
		margin-top: 15px;
	}

	.production {

		>.material,
		>.situation,
		>.bear {
			>h3 {
				text-align: left;
				font-size: 28px;
				padding: 35px 0;
			}

			// padding-bottom:55px;
		}

		// 作品情况
		>.situation {
			width: 1301px;
			margin: 0 auto;
			margin-top: 20px;

			>.situation_box {
				background-color: #fff;
				padding: 0 54px;

				>.material_box {
					text-align: left;
					padding: 30px 0;
					position: relative;

					>h3 {
						padding-top: 0px !important;
					}

					>.hint {
						font-size: 18px;
						color: #9D9D9D;
						text-align: left;
						padding-top: 15px;
						padding-left: 0;
					}

					>.material_input {
						width: 494px;
						height: 58px;
						margin-top: 30px;
						border: 1px solid #ccc;
						position: relative;
						padding: 0 23px;
						color: #9D9D9D;
						position: relative;

						>.material_icon {
							width: 44px;
							height: 44px;
						}
					}
				}

				>div {
					>h3 {
						padding-top: 40px;
						font-size: 28px;
						font-weight: 400;
					}
				}

				>.market,
				>.copyright {
					text-align: left;

					>.market_conten {
						padding: 50px 0;
					}
				}

				>h3 {
					font-size: 28px;
				}
			}
		}

		// 作品上传
		>.material,
		>.situation {
			>.material_box {
				background-color: #fff;
				font-size: 22px;
				padding: 10px 42px 49px 42px;
				position: relative;

				.download {
					width: 128px;
					height: 58px;
					line-height: 58px;
					background: #932224;
					color: #fff;
					position: absolute;
					top: 50px;
					right: 50px;
					z-index: 2;

					a {
						position: relative;
						z-index: 8;
					}
				}

				>.material_conten {
					padding-top: 39px;

					>.hint {
						font-size: 18px;
						color: #9D9D9D;
						text-align: left;
						padding-top: 15px;
						padding-left: 200px;
					}

					>div {
						position: relative;



						>.material_l {
							text-align: right;
							width: 162px;
							height: 100%;
							position: relative;

							>span {
								display: inline-block;
							}

							>p {
								font-size: 18px;
								color: #9D9D9D;
								position: absolute;
							}
						}

						>.material_input {
							border: 1px solid #ccc;
							width: 632px;
							height: 58px;
							margin: 0 20px;
							padding: 0 23px;
							color: #9D9D9D;
							position: relative;

							>.material_icon {
								width: 44px;
								height: 44px;
							}
						}
					}
				}
			}
		}
	}

	.presentType {
		position: absolute;
		right: 40px;
		color: #CD6428;
		font-size: 22px;
		top: 40px;
		max-width: 200px;
	}

	.photograph_conten {
		width: 194px;
		height: 194px;
		display: flex;
		align-items: center;
		cursor: pointer;
		margin: 0 auto;
		font-size: 12px;

		>.photograph_img {
			width: 90px;
			height: 90px;
		}
	}
</style>
<template>
	<div class="content">
		<div class="head">
			<div class="head_box">
				<div class="left icon_image">
					<img :src="obj.en_img" style="object-fit: contain;" />
				</div>
				<div class="right">
					<p>{{$t('workInformation.serialNumber')}}:<span>{{obj.number}}</span></p>
					<p>{{$t('workInformation.name')}}:<span>{{obj.en_name}}</span></p>
					<p>{{$t('workInformation.track')}}:<span>{{obj.en_track}}</span></p>
					<p>{{$t('workInformation.time')}}:<span>{{obj.create_time}}</span></p>
					<p style="padding-bottom: 0;">{{$t('workInformation.author')}}:
						<span v-for="(item,index) in obj.author_lsit" :key="index">{{item}}<span v-show="(index+1)!=obj.author_lsit.length">、</span></span>
						
					</p>
				</div>
				<p class="presentType">
					<span>
						{{$t('entry.presentTitle')}}
					</span>
					<span
						v-if="obj.en_check_state!=-1&&obj.en_check_state!=-2">{{$t('entry.presentArr')[obj.en_check_state]}}</span>
					<span v-if="obj.en_check_state==-2">{{$t('entry.presentArrF')[0]}}</span>
					<span v-if="obj.en_check_state==-1">{{$t('entry.presentArrF')[1]}}</span>
				</p>
			</div>
		</div>
		<div class="basic">
			<h3 class="tittle">{{$t('workInformation.essential')}}</h3>
			<div class="basicForm">
				<el-form ref="ruleForm" :inline="true" label-width="6.51042vw" class="demo-ruleForm">
					<el-form-item :label="$t('workInformation.name')" prop="name">
						<div class="input text_1_size">
							{{obj.en_name}}
						</div>
					</el-form-item>
					<el-form-item :label="$t('workInformation.track')" prop="name">
						<div class="input text_1_size">
							{{obj.en_track}}
						</div>
					</el-form-item>
					<el-form-item :label="$t('workInformation.type')" prop="name">
						<div class="input text_1_size">
							{{obj.en_category_name}}
						</div>
					</el-form-item>
					<el-form-item :label="$t('workInformation.number')" prop="name">
						<div class="input text_1_size">
							{{obj.author_num}}
						</div>
					</el-form-item>
					<el-form-item :label="$t('workInformation.author'+(index+1))" prop="name"
						v-for="(item,index) in obj.author_lsit" :key="index">
						<div class="input text_1_size">
							{{item}}
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="basic">
			<h3 class="tittle">{{$t('workInformation.describe')}}</h3>
			<div class="basicForm">
				<div class="essential_text">
					{{obj.en_describe}}
				</div>
			</div>
		</div>
		<div class="basic">
			<h3 class="tittle">{{$t('workInformation.antistop')}}</h3>
			<div class="basicForm">
				<div style="display: flex;">
					<div class="btn" v-for="(item,index) in obj.en_tag" :key="index">{{item}}</div>
					<!-- <div class="btn">中秋</div> -->
				</div>
			</div>
		</div>
		<div class="basic">
			<h3 class="tittle">{{$t('workInformation.material')}}</h3>
			<div class="basicForm">
				<div class="work_img">
					<div class="explain">
						{{$t('workInformation.explain')}}
					</div>
					<div class="img_box">
						<div v-for="(item,index) in obj.en_images" :key="index" class="img icon_image">
							<img :src="item">
						</div>
					</div>

				</div>
				<div class="work_box">
					<div class="box_content">
						<div class="title">
							{{$t('workInformation.vadio')}}
						</div>
						<div class="video">
							<video controls="controls">
								<source :src="obj.en_video" type="video/mp4" />
							</video>
						</div>
					</div>
					<div class="box_content">
						<div class="title">
							{{$t('workInformation.ppt')}}
						</div>
						<div class="video">
							<div class="photograph_conten" v-if="obj.en_explain[0]!=''">
								<a :href="obj.en_explain[0]">
									<div class="photograph_img icon_image">
										<img src="../../../assets/filepdf.png">
									</div>
								</a>
							</div>
							<el-empty :description="$t('newText.msg41')" style="padding: 0;" v-else></el-empty>
						</div>
					</div>
					<div class="box_content">
						<div class="title">
							{{$t('workInformation.word')}}
						</div>
						<div class="video">
							<div class="photograph_conten" v-if="obj.en_modeling[0]!=''">
								<a :href="obj.en_modeling[0]">
									<div class="photograph_img icon_image">
										<img src="../../../assets/dofile.png">
									</div>
								</a>
							</div>
							<el-empty :description="$t('newText.msg41')" style="padding: 0;" v-else></el-empty>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="production" >
			<!-- 作品情况 -->
			<div class="situation">
				<h3>{{$t('newText.msg3')}}</h3>
				<div class="situation_box" style="padding-bottom: 2.60417vw;">
					<!-- 销售情况 -->
					<div class="market">
						<!-- material.en_video -->
						<h3>{{$t('newText.msg4')}}<span class="sign_app_text">*</span></h3>
						<div class="whether_conten">
							<Whether :whether="$t('forinformation.data.marketArr')" :radio="obj.en_situation"></Whether>
						</div>
					</div>
					<div class="copyright">
						<h3 style="padding-top: 0;">{{$t('forinformation.data.copyrightName')}}<span
								class="sign_app_text">*</span> </h3>
						<Whether :whether="$t('forinformation.data.copyrightArr')" :radio="obj.en_copyright"></Whether>
					</div>
					<div class="icon_image" style="width: 17.90365vw;height: 12.63021vw; padding-bottom: 2.60417vw;" v-if="obj.en_copyright!=0">
						<img :src="obj.en_copyright_file" />
					</div>
				</div>
			</div>
		</div>
		<div class="basic" v-if="obj.situation[0].en_name!=''&&obj.situation.length==1">
			<h3 class="tittle">{{$t('workInformation.carry')}}</h3>
			<div class="basicForm" v-for="(item,index) in obj.situation" :key="index">
				<el-form :inline="true" label-width="6.51042vw" class="demo-ruleForm">
					<el-form-item :label="$t('workInformation.names')" prop="name">
						<div class="input text_1_size">
							{{item.en_name}}
						</div>
					</el-form-item>
					<el-form-item :label="$t('workInformation.awards')" prop="name">
						<div class="input text_1_size">
							{{item.en_prize}}
						</div>
					</el-form-item>
					<el-form-item :label="$t('workInformation.times')" prop="name">
						<div class="input text_1_size">
							{{item.reward_time}}
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import Whether from '@/components/whetherRadio.vue';
	export default {
		components: {
			Whether
		},
		data() {
			return {
				material: {
					// 介绍视频
					en_video: '',
					// 作品情况 1处于概念期 2无上市销售经历和计划 3有上市销售计划 4正在上市销售 5已停售’
					en_situation: '',
					// ‘是否进行过版权登记 0否 1是’
					en_copyright: '-1',
					// 版权书 如果版权登记为是 必选
					en_copyright_file: '',
					// 版权登记图片
					explainImg: [],
					// 作品图片
					en_images: [],
					// 建模文档
					en_modeling: [],
					// 说明文档
					en_explain: [],
				},
				obj: {}
			}
		},
		mounted() {
			this.workInformationList()
		},
		methods: {
			async workInformationList() {
				try {
					let _this = this
					let params = {
						id: this.$route.query.id,
					}
					let res = await this.$axios.get('entries/detail', {
						params: params
					});
					console.log(res)
					if (res.code == 1) {
						this.obj = res.data
						this.obj.en_img = this.obj.en_img.split(',')[0]
						this.obj.situation.forEach(item=>{
							item.reward_time=this.$util.formatTimeTwo(item.reward_time, 'Y-M-D')
						})
						// this.$router.push('personalCenter')
					}
				} catch (error) {
					console.log(error);
				}
			},
		}
	}
</script>
